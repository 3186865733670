import HookForm from "../Form/Form";

import phones from "../../assets/image/form/phons.png";

import "./FeedBack.css";

const FeedBack = ({ setStatusThanks }) => {
  return (
    <div className="feedBack relative -top-[50px] z-50 max-w-[1920px] mx-auto flex justify-between px-[68px]">
      <div className="feedBack-form-container max-w-[827px]">
        <h2 className="feedBack-form-title text-[60px] text-[#ECECEC] uppercase max-w-[700px]">
          бажаєте отримати швидку відповідь?
        </h2>
        <p className="feedBack-form-description text-[30px] text-[#ffffff]">
          Наші фахівці підберуть для вас чудові варіанти авто,враховуючи
          побажання і бюджет
        </p>

        <HookForm buttonStyle={"button-submit"} setStatus={setStatusThanks} />
      </div>
      <img src={phones} className="feedBack-form-img pr-2" alt="phone" />

      <div className="flex flex-col items-center relative -top-[170px] feedBack-reduce">
        <h2 className="feedBack-reduce-title text-[60px] text-[#ECECEC] font-bold uppercase">
          знижка <span className="text-[#FFD000]">100$</span> для військових
        </h2>
        <p className="feedBack-reduce-description text-[30px] text-[#ECECEC]">
          Частину прибутку відправляємо в ЗСУ і волонтерам
        </p>
      </div>
    </div>
  );
};

export default FeedBack;
