import { useState } from "react";
import HookForm from "../Form/Form";

import buttonBack from "../../assets/image/big-modal/button-back.png";
import "./SlideFive.css";
import { Helmet } from "react-helmet";

export const SlideFive = ({ close, data, prevSlide }) => {
  const [statusMessage, setStatusMessage] = useState(true);

  const modalClose = () => {
    setStatusMessage(true);
    close();
  };
  return (
    <div className="big-modal-slide-container five">
      {statusMessage ? (
        <>
          {/* <Helmet>

            <script>
              {`
                <!-- Meta Pixel Code -->
                <script>
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '535678358113442');
                fbq('track', 'Lead');
                </script>
                <noscript><img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=535678358113442&ev=PageView&noscript=1"
                /></noscript>
                <!-- End Meta Pixel Code -->

                <!-- Meta Pixel Code -->
                <script>
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '355939530725536');
                fbq('track', 'Lead');
                </script>
                <noscript><img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=355939530725536&ev=PageView&noscript=1"
                /></noscript>
                <!-- End Meta Pixel Code -->
              `}
            </script>
   
          </Helmet> */}
          <h2 className="big-modal-slide-title">ЗАЛИШИВСЯ ОСТАННІЙ КРОК</h2>
          <p className="big-modal-slide-text">
            Вкажіть Ваші контактні дані для отримання результату підбору
          </p>
          <button
            className="big-modal-slide-button-back"
            onClick={() => prevSlide()}
            disabled={false}
          >
            <img src={buttonBack} alt="button back" />
          </button>
        </>
      ) : (
        <>
          <div className="big-modal-form-close" onClick={() => modalClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 80 80"
              fill="none"
            >
              <rect
                x="0.00012207"
                y="7.46484"
                width="10.5595"
                height="102.578"
                transform="rotate(-45 0.00012207 7.46484)"
                fill="#D9D9D9"
              />
              <rect
                x="72.5333"
                width="10.5595"
                height="102.578"
                transform="rotate(45 72.5333 0)"
                fill="#D9D9D9"
              />
            </svg>
          </div>
        </>
      )}
      {statusMessage ? (
        <div className="big-modal-form-container">
          <>
            <div className="big-modal-form-wrapper">
              <HookForm
                buttonStyle={"big-modal-form-button"}
                setStatus={() => setStatusMessage(false)}
                classes={"newInputStyle"}
                data={data}
              />
            </div>
          </>
        </div>
      ) : (
        <div className="big-modal-form-sucses-wrapper">
          <h2 className="big-modal-form-sucses-title">
            ваша заявка успішно відправлена!
          </h2>
          <p className="big-modal-form-text-top ">
            Дякуємо! Наш менеджер зв'яжеться з Вами найближчим часом!
          </p>

          <a
            href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D"
            className="big-modal-form-link"
          >
            НАШ ІНСТАГРАМ
          </a>

          <p className="big-modal-form-text-bottom">
            РЕКОМЕНДУЄМО ОЗНАЙОМИТИСЬ З НАШИМ ІНСТАГРАМОМ
          </p>
        </div>
      )}
    </div>
  );
};
