import "./Button.css";

const Button = ({
  classesDiv = "",
  classesButton = "",
  text = "",
  onClick,
  style = {},
}) => {
  return (
    <div className={`${classesDiv} button__container button`}>
      <button onClick={onClick} className={`${classesButton} `} style={style}>
        <span>{text}</span>
      </button>
    </div>
  );
};

export default Button;
