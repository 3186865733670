import { useEffect, useState } from "react";

import carDeteilibg from "../../assets/image/services/car-deteiling.png";
import carCto from "../../assets/image/services/car-cto.png";

import "./CarService.css";

const data = [
  {
    img: carCto,
    title: "Власне СТО",
    services: [
      "РЕМОНТ ДВИГУНА",
      "ТРАНСМІСІЯ",
      "ХОДОВА ЧАСТИНА",
      "ГАЛЬМІВНА СИСТЕМА",
      "ТЕХНІЧНИЙ ОГЛЯД",
      "фільтра мастила",
      "РИХТУВАННЯ",
      "ЗАПЧАСТИНИ",
      "Інше",
    ],
  },
  {
    img: carDeteilibg,
    title: "DETAILING",
    services: [
      "МИЙКА",
      "ХІМ. ЧИСТКА",
      "ПОЛІРУВАННЯ",
      "АНТИСКРИП",
      "ОЗОНУВАННЯ",
      "ОБВІС",
      "ВІНІЛОВА ПЛІВКА",
      "АВТОКОСМЕТИКА",
      "ІНШЕ",
    ],
  },
  // {
  //   title: "SELL/BUY",
  //   services: [
  //     "ПРОДАТИ СВОЄ АВТО",
  //     "ЗАМОВТИ АВТО",
  //     "АВТОПІДБІР З ЕКСПЕРТОМ",
  //     "ВИСТАВИТИ АВТО НА ПРОДАЖ",
  //     "КРЕДИТУВАННЯ",
  //     "ЗНИЖКИ/ПРОМОКОДИ",
  //     "ОЦІНКА ВАРТОСТІ",
  //     "КОНСУЛЬТАЦІЯ",
  //     "Інше...",
  //   ],
  // },
];

const CarService = () => {
  const [activeSlide, setActiveSlide] = useState(true);

  // setInterval(() => setActiveSlide((state) => !state), 10000);

  useEffect(() => {
    setTimeout(() => setActiveSlide((state) => !activeSlide), 10000);
    // console.log(activeSlide);
  }, [activeSlide]);

  return (
    <div className="services relative">
      <div
        className={`${
          activeSlide ? "opacity-100" : "opacity-0"
        } services-container transition-all duration-1000`}
      >
        <img src={carCto} alt="car" className="relative z-50 services-img" />
        <ul className={` flex flex-col items-center service-list`}>
          <li className="car-service-title-item text-[53px] text-white font-bold mb-7 service-item">
            {data[0].title}
          </li>
          {data[0].services.map((service, i) => {
            return (
              <li
                key={service}
                className="car-service-item text-[53px] lowercase text-center text-white mb-7 service-item-service"
              >
                {service}
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className={`${
          activeSlide ? "opacity-0" : "opacity-100"
        } services-container transition-all duration-1000 absolute top-0 left-0`}
      >
        <img
          src={carDeteilibg}
          alt="car"
          className="relative z-50 services-img"
        />
        <ul
          className={` flex flex-col items-center relative service-list z-10`}
        >
          <li className="car-service-title-item text-[53px] text-white font-bold mb-7 service-item">
            {data[1].title}
          </li>
          {data[1].services.map((service, i) => {
            return (
              <li
                key={service}
                className="text-[53px] car-service-item lowercase text-center text-white mb-7 service-item-service"
              >
                {service}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CarService;
