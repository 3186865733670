import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import playIcon from "../../assets/image/review/playIcon.png";

import "./Slide.css";
import Button from "../Button/Button";



const Slide = ({ data: { videoUrl, car, capacity, years, transmission }, status }) => {
  const [showImg, setShowImg] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const playIconElement = (
    <button onClick={() => handleShowImg()}>
      <img src={playIcon} alt="play icon" />
    </button>
  );

  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    const fetchPreviewUrl = async () => {
      try {
        // Отримуємо ID відео з URL
        const videoId = getVideoIdFromUrl(videoUrl);
        // Складаємо URL прев'ю-зображення
        const previewUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
        setPreviewUrl(previewUrl);
      } catch (error) {
        console.error('Error fetching preview URL:', error);
      }
    };

    fetchPreviewUrl();
  }, [videoUrl]);

  // Функція для отримання ID відео з URL
  const getVideoIdFromUrl = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/);
    return match && match[1];
  };

  useEffect(() => {
    if (status === false) {
      setShowImg(false);
      setShowInfo(false);
    }
  }, [status]);

  const handleShowImg = () => {
    setShowInfo(false);
    setTimeout(() => setShowImg(true), 100);
  };

  const handleShowInfo = () => {
    setShowImg(false);
    setTimeout(() => setShowInfo(!showInfo), 100);
  };

  const changeWidthVideo = () => {
    const screenWidth = window.innerWidth;
    // console.log(screenWidth);

    if (screenWidth > 1600) {
      return "532px";
    } else if (screenWidth > 1200) {
      return "399px";
    } else if (screenWidth > 750) {
      return "542px";
    } else if (screenWidth > 450) {
      return "319px";
    } else if (screenWidth > 310) {
      return "300px";
    }
  };

  const changeHeightVideo = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth > 1600) {
      return showImg ? "792px" : "532px";
    } else if (screenWidth > 1200) {
      return showImg ? "568px" : "399px";
    } else if (screenWidth > 750) {
      return showImg ? "792px" : "532px";
    } else if (screenWidth > 450) {
      return showImg ? "454px" : "319px";
    } else if (screenWidth > 310) {
      return showImg ? "500px" : "300px";
    }
  };

  return (
    <div className="slide w-full max-w-[532px] h-full flex flex-col items-center">
      <div
        className={` slide-top transition-all duration-500 relative overflow-hidden`}
      >
        <ReactPlayer
          url={videoUrl}
          light={true}
          controls={false}
          playing={showImg}
          playIcon={playIconElement}
          onPlay={() => handleShowImg()}
          style={{
            transition: "all .5s",
          }}
          width={changeWidthVideo()}
          height={changeHeightVideo()}
        />
      </div>
      <div
        className={`${
          showImg ? "max-h-0 slide-bottom-not-active" : "max-h-[1000px] "
        }  overflow-hidden slide-bottom transition-all duration-500 review-info w-full flex flex-col items-center`}
      >
        <h3 className="slide-title text-3xl text-white my-5">
          ВИКОНАНЕ ЗАМОВЛЕННЯ
        </h3>
        <ul
          className={`w-full m-3 reviews-slide-list ${
            showInfo
              ? "max-h-[1000px] transition-all duration-300 overflow-hidden"
              : "max-h-0 transition-all duration-300 overflow-hidden"
          }`}
        >
          <li className="flex reviews-slide-item justify-between">
            <span className="text-3xl reviews-slide-questin text-white">
              Авто
            </span>
            <span className="text-[27px] reviews-slide-answer uppercase text-white">
              {car}
            </span>
          </li>
          <li className="flex reviews-slide-item justify-between">
            <span className="text-3xl reviews-slide-questin text-white">
              Об'єм двигуна
            </span>
            <span className="text-[27px] reviews-slide-answer uppercase text-white">
              {capacity}
            </span>
          </li>
          <li className="flex reviews-slide-item justify-between">
            <span className="text-3xl reviews-slide-questin text-white">
              Рік авто
            </span>
            <span className="text-[27px] reviews-slide-answer uppercase text-white">
              {years}
            </span>
          </li>
          <li className="flex reviews-slide-item justify-between">
            <span className="text-3xl reviews-slide-questin text-white">
              Коробка передач
            </span>
            <span className="text-[27px] reviews-slide-answer uppercase text-white">
              {transmission}
            </span>
          </li>
        </ul>
        <div
          className={`${
            showImg
              ? "absolute  bottom-4 left-1/2 -translate-x-1/2"
              : "relative"
          } slide-button`}
        >
          <Button
            onClick={() => handleShowInfo()}
            text={showInfo ? "Назад" : "Інформація"}
            classesButton={"slide-button"}
            classesDiv={"slide-button"}
          />
        </div>
      </div>
    </div>
  );
};

export default Slide;
