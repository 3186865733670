import ToggleTheam from "../ToggleTheam/ToggleTheam";
import { Squash as Hamburger } from "hamburger-react";
import logo from "../../assets/image/logo.png";
import { useState } from "react";
import "./Header.css";

const data = ["Відгуки", "Трекер", "Контакти", "Автосервіс", "Переваги"];

const Header = ({ setDarkMode, darkMode }) => {
  // const [hover, setHover] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const [highlightedIndex, setHighlightedIndex] = useState(null);

  const handleMouseOver = (index) => {
    setHighlightedIndex(index);
  };

  const handleMouseLeave = () => {
    setHighlightedIndex(null);
  };

  return (
    <header className="header w-full flex  justify-center header-bg absolute top-0 left-0 z-50">
      <div className="flex justify-between items-center px-[34px] py-5 max-w-[1920px] w-full header-desktop">
        <a href="#">
          <img src={logo} alt="logo" className="w-[160px]" />
        </a>
        <ul className="menu-list flex max-w-[832px] w-full justify-between px-3">
          {data.map((item) => {
            return (
              <li
                key={item}
                onMouseOver={() => handleMouseOver(item)}
                onMouseLeave={() => handleMouseLeave(null)}
                className={`
                  ${
                    highlightedIndex !== item && highlightedIndex !== null
                      ? "menu-link-animation transition-all duration-500"
                      : "menu-link-animation-white transition-all duration-500 "
                  }
                    menu-item menu-link text-2xl font-medium transition-all duration-500
                  `}
              >
                <a
                  onMouseOver={() => handleMouseOver(item)}
                  onMouseLeave={() => handleMouseLeave(null)}
                  href={`#${item}`}
                  className="transition-all duration-500"
                >
                  {item}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="">
          <ToggleTheam setDarkMode={setDarkMode} darkMode={darkMode} />
        </div>
      </div>
      <div className="header-mobile">
        <div className="header-mobile-toggle-wrap">
          <div className="header-mobile-toggle-wrap-absolute">
            <ToggleTheam setDarkMode={setDarkMode} darkMode={darkMode} />
          </div>
        </div>

        <img src={logo} alt="logo" className="header-modile-img" />

        <Hamburger toggled={openMenu} color="#D9D9D9" toggle={setOpenMenu} />

        {openMenu ? (
          <ul className="header-mobile-menu menu-list  w-full justify-between px-3 transition-all duration-500">
            {data.map((item) => {
              return (
                <li
                  key={item}
                  onClick={() => setOpenMenu(false)}
                  className={`menu-item menu-link text-2xl font-medium transition-all duration-500`}
                >
                  <a href={`#${item}`} className="transition-all duration-500">
                    {item}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
