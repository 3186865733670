import React from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";

import SendFormService from "../../service/services";

import "./Form.css";

export default function HookForm({
  formStyle,
  labelStyle,
  label,
  buttonStyle,
  classes,
  setStatus,
  data,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const { sendForm } = SendFormService();

  // setValue("phone", "+380");

  const onSubmit = async (values) => {
    setValue("name", values.name.trim());
    const transform = `
      Запит з сайту
      Ім'я: ${values.name},
      Телефон: ${values.phone},
      ${
        data !== undefined &&
        data.body !== null &&
        data.typeOil !== null &&
        data.country &&
        data.price
          ? `
          Тип кузова ${data.body},
          Тип палива ${data.typeOil},
          Країна доставки: ${data.country},
          Бюджет: ${data.price}
        `
          : ""
      }
    `;

    try {
      const request = await sendForm(transform);
      console.log(await request);
      setValue("name", "");
      setValue("phone", "");
      setStatus();
    } catch (e) {
      console.log(e);
    }
  };

  const inputNumberValue = (phoneInput) => {
    if (!phoneInput.value.startsWith("+380")) {
      phoneInput.value = "+380" + phoneInput.value.replace(/\D/g, "");
    }
  };

  const valueTrim = (e) => {
    setValue(e.target.id, e.target.value.trim());
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={(e) => valueTrim(e)}
      style={formStyle}
    >
      <FormLabel
        fontFamily=""
        style={labelStyle}
        fontWeight={900}
        fontSize="36px"
      >
        {label}
      </FormLabel>

      <FormControl
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
        }}
        isInvalid={errors.name}
      >
        <Input
          // mb={"30px"}
          border={"none"}
          borderRadius={0}
          focusBorderColor="rgba(0, 0, 0, 0.38)"
          className={`formInputs ${classes}`}
          id="name"
          placeholder={`Ім'я`}
          {...register("name", {
            trim: true,
            required: "Поле імені обов'язкове",
          })}
        />
        <FormErrorMessage
          fontSize="16px"
          fontFamily="myBody"
          fontWeight={"400"}
          style={{ position: "absolute", bottom: "8px" }}
        >
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      {/* background: linear-gradient(90deg, rgba(156, 156, 156, 0.41) 0%, #414141 100%);

box-shadow: -3px 4px 7.2px 1px rgba(0, 0, 0, 0.38); */}
      <FormControl
        isInvalid={errors.phone}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Input
          border={"none"}
          borderRadius={0}
          focusBorderColor="rgba(0, 0, 0, 0.38)"
          className={`formInputs ${classes}`}
          borderColor={errors.phone && errors.phone.message ? "redC.100" : ""}
          id="phone"
          onChange={(e) => console.log(e)}
          placeholder="Номер телефону"
          defaultValue="+380"
          {...register("phone", {
            required: "Це поле обов'язкове",

            pattern: {
              value: /^(\+?3?8?)?0\d{9}$/,
              message: "Формат телефону +380999999999",
            },
          })}
        />
        <FormErrorMessage
          fontSize="16px"
          fontFamily="myBody"
          fontWeight={"400"}
          style={{ position: "absolute", bottom: "8px" }}
        >
          {errors.phone && errors.phone.message}
        </FormErrorMessage>
      </FormControl>

      <Button
        mt={6}
        width={"100%"}
        // colorScheme="teal"
        borderRadius={0}
        isLoading={isSubmitting}
        type="submit"
        className={buttonStyle}
        // onClick={}
      >
        НАДІСЛАТИ
      </Button>
    </form>
  );
}
