import buttonBack from "../../assets/image/big-modal/button-back.png";
import gasoline from "../../assets/image/big-modal/gasoline.png";
import gasBenz from "../../assets/image/big-modal/gas-benz.png";
import diesel from "../../assets/image/big-modal/diesel.png";
import electro from "../../assets/image/big-modal/electro.png";
import hibrid from "../../assets/image/big-modal/hibrid.png";
import gasolineHover from "../../assets/image/big-modal/gasoline-hover.png";
import gasBenzHover from "../../assets/image/big-modal/gas-benz-hover.png";
import dieselHover from "../../assets/image/big-modal/diesel-hover.png";
import electroHover from "../../assets/image/big-modal/electro-hover.png";
import hibridHover from "../../assets/image/big-modal/hibrid-hover.png";
import gasolineActive from "../../assets/image/big-modal/gasoline-active.png";
import gasBenzActive from "../../assets/image/big-modal/gas-benz-active.png";
import dieselActive from "../../assets/image/big-modal/diesel-active.png";
import electroActive from "../../assets/image/big-modal/electro-active.png";
import hibridActive from "../../assets/image/big-modal/hibrid-active.png";

import gasolineSmall from "../../assets/image/big-modal/gasoline-small.png";
import gasBenzSmall from "../../assets/image/big-modal/gas-benz-small.png";
import dieselSmall from "../../assets/image/big-modal/diesel-small.png";
import electroSmall from "../../assets/image/big-modal/electro-small.png";
import hibridSmall from "../../assets/image/big-modal/hibrid-small.png";
import gasolineSmallActive from "../../assets/image/big-modal/gasoline-small-active.png";
import gasBenzSmallActive from "../../assets/image/big-modal/gas-benz-small-active.png";
import dieselSmallActive from "../../assets/image/big-modal/diesel-small-active.png";
import electroSmallActive from "../../assets/image/big-modal/electro-small-active.png";
import hibridSmallActive from "../../assets/image/big-modal/hibrid-small-active.png";

import "./SlideTwo.css";

const slideTwoData = [
  {
    src: gasoline,
    srcHover: gasolineHover,
    srcActive: gasolineActive,
    alt: "Бензин",
    classHover: "big-modal-slide-two-item-hover",
    classActive: "big-modal-slide-two-item-active-active",
    srcSmall: gasolineSmall,
    srcActiveSmall: gasolineSmallActive,
  },
  {
    src: gasBenz,
    srcHover: gasBenzHover,
    srcActive: gasBenzActive,
    alt: "Газ/бензин",
    classHover: "big-modal-slide-two-item-hover",
    classActive: "big-modal-slide-two-item-active-active",
    srcSmall: gasBenzSmall,
    srcActiveSmall: gasBenzSmallActive,
  },
  {
    src: diesel,
    srcHover: dieselHover,
    srcActive: dieselActive,
    alt: "Дизель",
    classHover: "big-modal-slide-two-item-hover",
    classActive: "big-modal-slide-two-item-active-active",
    srcSmall: dieselSmall,
    srcActiveSmall: dieselSmallActive,
  },
  {
    src: electro,
    srcHover: electroHover,
    srcActive: electroActive,
    alt: "Електро",
    classHover: "big-modal-slide-two-item-hover",
    classActive: "big-modal-slide-two-item-active-active",
    srcSmall: electroSmall,
    srcActiveSmall: electroSmallActive,
  },
  {
    src: hibrid,
    srcHover: hibridHover,
    srcActive: hibridActive,
    alt: "Гібрид",
    classHover: "big-modal-slide-two-item-hover",
    classActive: "big-modal-slide-two-item-active-active",
    srcSmall: hibridSmall,
    srcActiveSmall: hibridSmallActive,
  },
];

export const SlideTwo = ({ status, setThecond, prevSlide, nextSlide }) => {
  const screenWidth = window.innerWidth;
  return (
    <div className="big-modal-slide-container">
      <h2 className="big-modal-slide-title">ОБЕРІТЬ ТИП ПАЛИВА</h2>
      <p className="big-modal-slide-text">для підбору авто(2/5)</p>
      <button
        className="big-modal-slide-button-back"
        onClick={() => prevSlide()}
        disabled={false}
      >
        <img src={buttonBack} alt="button back" />
      </button>

      <ul className="big-modal-slide-two-list ">
        {slideTwoData.map((item) => {
          const {
            src,
            alt,
            classHover,
            classActive,
            srcHover,
            srcActive,
            srcSmall,
            srcActiveSmall,
          } = item;
          return (
            <li
              className="big-modal-slide-two-item"
              onClick={(e) => {
                setThecond((state) => {if (
                  Array.isArray(state) &&
                  state.length > 0 &&
                  state[0] !== "Не важливо"
                ) {
                  return [
                    ...state,
                    e.currentTarget.children[1].getAttribute("alt"),
                  ];
                } else {
                  return [e.currentTarget.children[1].getAttribute("alt")];
                }});
              }}
              key={src}
            >
              {screenWidth > 1000 ? (
                <img src={srcHover} alt={alt} className={classHover}></img>
              ) : (
                ""
              )}
              <img
                src={screenWidth > 1000 ? src : srcSmall}
                alt={alt}
                className="big-modal-slide-two-img"
              />
              <img
                src={screenWidth > 1000 ? srcActive : srcActiveSmall}
                alt={alt}
                className={`big-modal-slide-two-item-active ${
                  Array.isArray(status) && status.includes(alt) ? classActive : ""
                } `}
              ></img>
            </li>
          );
        })}
      </ul>
      <div className="slide-buttons flex justify-between">
        <button
          className={`big-modal-button ${
            Array.isArray(status) && status[0] === "Не важливо" ? "big-modal-button-active" : ""
          } `}
          onClick={() => setThecond(["Не важливо"])}
        >
          Поки не визначився / не важливо
        </button>
        <button
          disabled={status === null ? true : false}
          className={`big-modal-button-next `}
          onClick={() => nextSlide()}
        >
          Далі
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};
