import { useState } from "react";
import HookForm from "../Form/Form";

import "./SmallModal.css";

const SmallModal = ({ close, status }) => {
  const [statusMessage, setStatusMessage] = useState(true);

  const modalClose = () => {
    setStatusMessage(true);
    close();
  };

  return (
    <div className="small-modal z-50 ">
      <div className="small-modal-container">
        <div className="small-modal-close" onClick={() => modalClose()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
          >
            <rect
              x="33.4551"
              y="9"
              width="4.64572"
              height="34.5848"
              transform="rotate(45 33.4551 9)"
              fill="#D9D9D9"
            />
            <rect
              x="9"
              y="12.2852"
              width="4.64572"
              height="34.5848"
              transform="rotate(-45 9 12.2852)"
              fill="#D9D9D9"
            />
          </svg>
        </div>
        {status || statusMessage ? (
          <>
            <h2 className="small-modal-title">Зворотній дзвінок</h2>
            <p className="small-modal-text">
              Вкажіть Ваші контактні дані і ми Вам передзвонимо
            </p>
            <div className="small-modal-wrapper">
              <HookForm
                buttonStyle={"small-modal-button"}
                setStatus={() => setStatusMessage(false)}
                data={{}}
              />
            </div>
          </>
        ) : (
          <>
            <h2 className="small-modal-sucses-title">
              ваша заявка успішно відправлена!
            </h2>
            <p className="small-modal-text-top ">
              Дякуємо! Наш менеджер зв'яжеться з Вами найближчим часом!
            </p>

            <a href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D" className="small-modal-link">
              НАШ ІНСТАГРАМ
            </a>

            <p className="small-modal-text-bottom">
              РЕКОМЕНДУЄМО ОЗНАЙОМИТИСЬ З НАШИМ ІНСТАГРАМОМ
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default SmallModal;
