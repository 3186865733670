import buttonBack from "../../assets/image/big-modal/button-back.png";
import sedan from "../../assets/image/big-modal/sedan.png";
import hechback from "../../assets/image/big-modal/hechback.png";
import universal from "../../assets/image/big-modal/universal.png";
import krosower from "../../assets/image/big-modal/krosower.png";
import jeep from "../../assets/image/big-modal/jeep.png";
import miniven from "../../assets/image/big-modal/miniven.png";
import sedanSmall from "../../assets/image/big-modal/sedan-small.png";
import hechbackSmall from "../../assets/image/big-modal/hechback-small.png";
import universalSmall from "../../assets/image/big-modal/universal-small.png";
import krosowerSmall from "../../assets/image/big-modal/krosower-small.png";
import jeepSmall from "../../assets/image/big-modal/jeep-smal.png";
import minivenSmall from "../../assets/image/big-modal/miniven-small.png";

import sedanActive from "../../assets/image/big-modal/sedan-small-hover.png";
import hechbackActive from "../../assets/image/big-modal/hechback-small-active.png";
import universalActive from "../../assets/image/big-modal/universal-small-active.png";
import krosowerActive from "../../assets/image/big-modal/krosower-small-active.png";
import jeepActive from "../../assets/image/big-modal/jeep-smal-active.png";
import minivenActive from "../../assets/image/big-modal/miniven-small-active.png";

const slideOneData = [
  {
    src: sedan,
    alt: "Седан",
    classHover: "big-modal-slide-one-item-hover-top",
    classActive: "big-modal-slide-one-item-active-top",
    srcSmall: sedanSmall,
    srcActive: sedanActive,
  },
  {
    src: hechback,
    alt: "Хетчбек",
    classHover: "big-modal-slide-one-item-hover-top",
    classActive: "big-modal-slide-one-item-active-top",
    srcSmall: hechbackSmall,
    srcActive: hechbackActive,
  },
  {
    src: universal,
    alt: "Універсал",
    classHover: "big-modal-slide-one-item-hover-top",
    classActive: "big-modal-slide-one-item-active-top",
    srcSmall: universalSmall,
    srcActive: universalActive,
  },
  {
    src: krosower,
    alt: "Кросовер",
    classHover: "big-modal-slide-one-item-hover-bottom",
    classActive: "big-modal-slide-one-item-active-bottom",
    srcSmall: krosowerSmall,
    srcActive: krosowerActive,
  },
  {
    src: jeep,
    alt: "Позашляховик",
    classHover: "big-modal-slide-one-item-hover-bottom",
    classActive: "big-modal-slide-one-item-active-bottom",
    srcSmall: jeepSmall,
    srcActive: jeepActive,
  },
  {
    src: miniven,
    alt: "Мінівен",
    classHover: "big-modal-slide-one-item-hover-bottom",
    classActive: "big-modal-slide-one-item-active-bottom",
    srcSmall: minivenSmall,
    srcActive: minivenActive,
  },
];

export const SlideOne = ({ status, setFirst, nextSlide, close }) => {
  const screenWidth = window.innerWidth;
  console.log(status);
  return (
    <div className="big-modal-slide-container">
      <h2 className="big-modal-slide-title">ОБЕРІТЬ ТИП КУЗОВА</h2>
      <p className="big-modal-slide-text">для підбору авто(1/5)</p>
      <button
        className="big-modal-slide-button-back"
        disabled={false}
        onClick={() => close()}
      >
        <img src={buttonBack} alt="button back" />
      </button>

      <ul className="big-modal-slide-one-list ">
        {slideOneData.map((item) => {
          const { src, alt, classHover, classActive, srcActive, srcSmall } =
            item;
          return (
            <li
              className="big-modal-slide-one-item"
              onClick={(e) => {
                setFirst((state) => {
                  if (
                    Array.isArray(state) &&
                    state.length > 0 &&
                    state[0] !== "Не важливо"
                  ) {
                    return [
                      ...state,
                      e.currentTarget.children[1].getAttribute("alt"),
                    ];
                  } else {
                    return [e.currentTarget.children[1].getAttribute("alt")];
                  }
                });
              }}
              key={src}
            >
              <div className={classHover}></div>
              <img
                src={screenWidth > 1000 ? src : srcSmall}
                alt={alt}
                className="big-modal-slide-one-img transition-all duration-300"
              />
              {
                screenWidth > 1000
                  ? status !== null &&
                    status.length > 0 &&
                    status.map((item) =>
                      item === alt ? <div className={classActive}></div> : null
                    )
                  : // status === alt ? (
                    //   <div className={classActive}></div>
                    // ) : null
                    status !== null &&
                    status.length > 0 &&
                    status.map((item) =>
                      item === alt ? (
                        <img
                          src={srcActive}
                          className="transition-all duration-300 absolute w-full h-full top-0 left-0"
                          alt=""
                        />
                      ) : null
                    )
                // status === alt ? (
                //   <img
                //     src={srcActive}
                //     className="transition-all duration-300 absolute w-full h-full top-0 left-0"
                //     alt=""
                //   />
                // ) : null
              }
            </li>
          );
        })}
      </ul>
      <div className="slide-buttons flex justify-between">
        <button
          className={`big-modal-button ${
            Array.isArray(status) && status[0] === "Не важливо"
              ? "big-modal-button-active"
              : ""
          } `}
          onClick={() => setFirst(["Не важливо"])}
        >
          Поки не визначився / не важливо
        </button>
        <button
          disabled={status === null ? true : false}
          className={`big-modal-button-next `}
          onClick={() => nextSlide()}
        >
          Далі
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};
