import BenefitsList from "../BenefitsList/BenefitsList";
import Button from "../Button/Button";
import "./WhyWe.css";

const WhyWe = ({ openModal }) => {
  return (
    <div className="why-we relative z-50 row-span-1 col-span-1 max-w-[1920px] w-full mx-auto ">
      <div className="why-we-top flex flex-col items-center mb-[50px]">
        <h2 className="why-we-title text-6xl text-white mb-3">ЧОМУ МИ?</h2>
        <p className="why-we-description text-3xl text-white mb-4">
          Наші переваги перед конкурентами
        </p>

        <BenefitsList />
      </div>

      <div className="why-we-bottom-container max-w-[1135px] relative left-[152px]">
        <h2 className="why-we-bottom-title text-6xl text-white mb-3">
          ХОЧЕТЕ ПІДІБРАТИ ТА РОЗРАХУВАТИ ВАРТІСТЬ АВТОМОБІЛЯ?
        </h2>
        <p className="why-we-bottom-proposition text-3xl text-white mb-[63px] font-normal">
          Зв’яжіться з нами прямо зараз, щоб ми
          <span className="font-bold"> безкоштовно</span> допомогли вам з
          вибором авто яке відповідає вашим вимогам і бюджету
        </p>
        <Button
          onClick={() => openModal()}
          text={"Консультація"}
          classesDiv="why-button-container "
          classesButton="whywe-button"
        />
      </div>
    </div>
  );
};

export default WhyWe;
