const SendFormService = () => {
  const botToken = "7154640844:AAFcI4CUAVC3cEAYkuyi5YYX9OVRxO46nFo";
  const chatId = "-1002024656503";
  const url = `https://api.telegram.org/bot${botToken}/`;

  const sendForm = async (formData) => {
    // const transform = `
    //   Запит з сайту https://www.kartinynaholste.in.ua/
    //   Ім'я: ${formData.name},
    //   Номер телефону: ${formData.phone},

    // `
    const data = {
      chat_id: chatId,
      text: formData,
    };

    try {
      const request = await fetch(`${url}sendMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!request.ok) {
        // setThenkYou(true);
        // document.body.style.overflow = 'hidden';
      }
      return await request.json();
    } catch (e) {
      console.log(e);
      return Promise.resolve(e);
    }
  };

  return {
    sendForm,
  };
};

export default SendFormService;
